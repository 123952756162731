import React,{useState} from "react";
import '../../pages/styles/footer.css';

function  Feeter()
{
    const [FooterHoveredLink, setFooterHoveredLink] = useState(false);


    return (
        <div>
            <footer className={`footer ${FooterHoveredLink ? 'expanded' : ''}`}
                onMouseEnter={() => setFooterHoveredLink(true)}
                onMouseLeave={() => setFooterHoveredLink(false)}
                >
                <div className="container">
                    <span className="text-muted">Buildtivity ApS | CVR: 43976486 | Contact: <a style={{textDecoration:"none", color:"white"}} href="mailto:info@builtivity.dk" target="_blank" rel="noopener noreferrer">info@buildtivity.dk</a></span>
                </div>
            </footer>
        </div>
    );
}
export default Feeter;