import { useParams, useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import { fetchDatafromQuery, fetchQueryDataCat } from '../../../components/APIS/categoryAPI';
import MemoizedDetailBoxes from '../LoadedProducts';
import FilterQuery from '../Filter/Filter';
import styles from './kategoridetailstyle.module.css'; 
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import Loader from '../../../components/Utils/Loader';
import FilterSortButton from '../filterSortButton/filterSortButton';
import UnitTranslator from '../../../components/Utils/Helpers';



function KategoriDetailPage() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [dat, setDat] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [unit, setUnit] = useState({});
  const [availableUnits, setAvailableUnits] = useState([]);
  const [orgData, setOrgData] = useState({});
  const [page, setPage] = useState(() => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get('pn')) || 1; 
  });
  const [maxPage, setMaxPage] = useState(100);
  const [productLoad, setProductLoad] = useState(true);
  const [isSubkategori, setIsSubkategori] = useState(location.pathname.includes('/subkategori/'));
  const [query, setQuery] = useState({});
  const [fquery, setFquery] = useState([]);
  const [title , setTitle] = useState('');

  const [prevQuery, setPrevQuery] = useState({});
  
  
  const formatSubcategory = (name) => {
    return name
      .replace(/ae/g, 'æ')                  
      .replace(/([A-Z])/g, ' $1')             
      .replace(/^./, str => str.toUpperCase()); 
  };
  const titleCheck = () => {
    if (isSubkategori) {
      setTitle(formatSubcategory(id));
    } else {
      setTitle(id);
    }
  };

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   try {
  //     let data = [];
  //     if(isSubkategori){
  //       const lcID =id.replace(/-/g, ' ');
  //       data = await fetchQueryDataCat({ id: lcID});
  //     }else{
  //       const lcID = categoryswitch(id);
  //       data = await fetchQueryDataCat({ id: lcID });
  //     }
  //     setOrgData(data);

  //     const availableUnits = data.map((item) => item.unit);
  //     setAvailableUnits([...new Set(availableUnits)]);
  //     let selectedUnit = '';

  //     if (availableUnits.includes('kg')) {
  //       selectedUnit = 'kg';
  //     } else {
  //       selectedUnit = availableUnits[Math.floor(Math.random() * availableUnits.length)];
  //     }

  //     const selectedData = data.filter((item) => item.unit === selectedUnit);
  //     const selectedObject = selectedData.length > 0 ? selectedData[0] : null;

  //     setUnit(selectedUnit);
  //     setDat(selectedObject);
  //     // console.log(dat);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const fetchDataOnLoad = async () => {
    try {
      let data = [];
      if(isSubkategori){
        const lcID =id.replace(/-/g, ' ');
        data = await fetchQueryDataCat({ id: lcID});
      }else{
        const lcID = categoryswitch(id);
        data = await fetchQueryDataCat({ id: lcID });
      }
      setOrgData(data);

      const availableUnits = data.map((item) => item.unit);
      setAvailableUnits([...new Set(availableUnits)]);
      let selectedUnit = '';

      if (availableUnits.includes('kg')) {
        selectedUnit = 'kg';
      } else {
        selectedUnit = availableUnits[Math.floor(Math.random() * availableUnits.length)];
      }

      const selectedData = data.filter((item) => item.unit === selectedUnit);
      const selectedObject = selectedData.length > 0 ? selectedData[0] : null;

      setUnit(selectedUnit);
      setDat(selectedObject);
      // console.log(dat);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
    }
  };


  // Fetch data on component load
  useEffect(() => {
    fetchDataOnLoad();
    titleCheck();

  }, []);

  const handleUnitChange = (e) => {
    const selectedData = orgData.filter((item) => item.unit === e.target.value);
    const selectedObject = selectedData.length > 0 ? selectedData[0] : null;
    setUnit(e.target.value);
    setDat(selectedObject);
    setPage(1);
  };

  const categoryswitch = (cat) => {

    switch (cat) {
      case 'Facade':
        return 'facade';
      case 'Fundament':
        return 'fundament';
      case 'Gulv':
        return 'gulv';
      case 'Indervæg':
        return 'inderveg';
      case 'Isolering':
        return 'isolering';
      case 'Konstruktion':
        return 'konstruktion';
      case 'Loft':
        return 'loft';
      case 'Præfabrikerede Elementer':
        return 'prefabrikeredeElementer';
      case 'Tag':
        return 'tag';
      default:
        return cat.toLowerCase();
    }
  };
  
  const handleUnitBox = () => {
    return (
      <div className={styles.unitBox}>
        {availableUnits.map((unitOption) => (
          <div
            key={unitOption}
            onClick={() => handleUnitChange({ target: { value: unitOption } })}
            className={`${styles.unitOption} ${unit === unitOption ? styles.selected : ''}`}
          >
            {UnitTranslator(unitOption)}
          </div>
        ))}
      </div>
    );
  };

  // Add productLoad set to true when changing pages
  const pagePlus = () => {
    setPage((prevPage) => Math.min(prevPage + 1, maxPage));
    
  };

  const pageMinus = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageSelect = (pageNum) => {
    setPage(pageNum);
  };

  const renderPagebtn = (max, current) => {
    if (max === 1) {
      return <p className={styles.pageNumber}>Side: 1</p>;
    }

    let pages = [];

    if (max <= 6) {
      pages = Array.from({ length: max }, (_, i) => i + 1);
    } else if (current <= 3) {
      pages = [1, 2, 3, 4, 5, '...', max];
    } else if (current >= max - 2) {
      pages = [1, '...', max - 4, max - 3, max - 2, max - 1, max];
    } else {
      pages = [
        1,
        '...',
        current - 2,
        current - 1,
        current,
        current + 1,
        current + 2,
        '...',
        max,
      ];
    }

    return (
      <div className={styles.numberBox}>
        {current > 1 && (
          <button className={styles.navButton} onClick={pageMinus}>
            <IoIosArrowBack className={styles.arrowIcon} />
          </button>
        )}

        {pages.map((pageNum, index) => (
          <span key={index}>
            {pageNum === '...' ? (
              <span className={styles.dots}>. . .</span>
            ) : (
              <button
                className={`${styles.unitOption} ${pageNum === current ? styles.selected : ''}`}
                onClick={() => handlePageSelect(pageNum)}
              >
                {pageNum}
              </button>
            )}
          </span>
        ))}

        {current < max && (
          <button className={styles.navButton} onClick={pagePlus}>
            <IoIosArrowForward className={styles.arrowIcon} />
          </button>
        )}
      </div>
    );
  };

  
  useEffect(() => {
    const fetchData = async () => {
      console.log("fetching data");
        // Update query parameters in the URL only if they differ
    const params = new URLSearchParams(location.search);
    params.set("pn", page);
    const newSearch = params.toString();
    if (location.search !== `?${newSearch}`) {
      navigate(`${location.pathname}?${newSearch}`, { replace: true });
      }
      setIsLoading(true);
      if (Object.keys(query).length > 0) {
        try {
          const updatedQuery = { ...query, pageNumber: page };
          const data = await fetchDatafromQuery(updatedQuery, isSubkategori);
          setProducts(data.data);
          setMaxPage(data.maxPageNumber);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
          setProductLoad(false);
        }
      }
    };
  
      fetchData();
  }, [page,query]); // Optimize dependencies
  





  
  
  const itemQueryList = (query) => {
    
    if (!query || typeof query !== "object") {
      // console.warn("Invalid input: query must be a non-null object.");
      return [];
    }
  
    let list = [];

    // Iterate through the key-value pairs of the object
    for (const [key, value] of Object.entries(query)) {
      // Skip null, undefined, or empty arrays
      if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)|| key === "unit" || key === "category"|| key === "pageNumber" || key === "pageSize") {
        continue;
      }
      if (value.length>1 ) {
        Object.values(value).map((val) => {
          if (list.find((item) => item.key === key && item.value === val)) {
            return;
          }
          list.push({ key, value: val });
        }
        );
      } else {
        // Add the key-value pair to the list
        list.push({ key, value });
      }
    }

  
    return list;
  };
  
  
  return (
    <div className={styles.pageContainer}>
      <div className={styles.filterContainer}>
        <h3>Filter</h3>
        <div>
          {dat && (
            <FilterQuery
              setQuery={setQuery}
              data={dat}
              pageN={page}
              setPageN={setPage}
              setMaxPage={setMaxPage}
              unit={unit}
              setUnit={setUnit}
              subCategory={id}
              setIsLoading={setIsLoading}
              setProductLoad={setProductLoad}
            />
          )}
        </div>
      </div>
      
      <div className="content-container">
        <div id="title_and_buttons">
          <div id="kategori-title">
            <h1>{title}</h1>
            {dat && handleUnitBox()}
          
          </div>
          <div id="queryboxes" className={styles.queryboxContainer}>
            {itemQueryList(query).map(({ key, value }) => (
              <FilterSortButton
                key={`${key}-${value}`} // Unique key
                value={{ key, value }} // Pass key-value as an object
                setQuery={setQuery} // Pass the setQuery function
              />
            ))}
          </div>



        </div>
        {productLoad ? (
              <div className={styles.loader}>
                <Loader sizex={150} />
              </div>
            ) : products && products.length > 0 ? (
              <>
                <div>{renderPagebtn(maxPage, page)}</div>
                  <div className={styles.detailBoxGroup}>
                      <div className={styles.loaderContainer}>
                        <MemoizedDetailBoxes
                          products={products}
                          Unit={unit}
                          category={dat.category}
                        />
                        {isLoading && (
                          <div className={styles.overlay}>
                           

                          </div>
                        )}
                      </div>
                  </div>

                <div>{renderPagebtn(maxPage, page)}</div>
              </>
            ) : productLoad ? (
              <p>No products matching current filter</p>
            )  : null}
      </div>
    </div>
  );
}

export default KategoriDetailPage;
