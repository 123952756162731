import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Navbar from './components/Utils/navbar';
import Feeter from './components/Utils/footer';
import FeedbackButton from './components/FeedbackButton';
import { GlobalProvider } from './GlobalContext';

// Import pages
import MainPage from './pages/mainpage/MainPage';
import KategoriPage from './pages/KategoriPage';
import OmPage from './pages/OmPage/OmPage';
import FaqPage from './pages/FaqPage';
import ProjektPage from './pages/ProjektPage'
import KategoriDetailPage from './pages/Kategori/kategoridetailpage/KategoriDetailPage';
import DetailPage from './pages/Kategori/DetailPage';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/LoginPage/RegisterPage';
import ResetPasswordPage from './pages/LoginPage/ResetPasswordPage';
import Profile from './pages/Profilepage';
import ProjectDetails from './pages/Project/ProjectDetails'
import Settings from './pages/ProfileMenu/settings';
import Dev from './pages/dev';
import UnderConstruction from './pages/Construction';
import ProducentTable from './pages/Upload/producentTable';
import Correctpage from './pages/Upload/Correctpage';
import ResolveVersionPage from './pages/Upload/Version Control/ResolveVersionPage'
import StudentRegisterPage from './pages/LoginPage/StudentRegisterPage';
import ArkEngRegisterPage from './pages/LoginPage/ArkEngRegisterPage';
import UserPlus from './pages/LoginPage/UserRegister';
import LinkUser from './pages/LoginPage/Link';

import { ProtectedRoute } from './components/auth';
import './App.css';


// Page transition animation variants
const pageVariants = {
  initial: { opacity: 0, scale: 1 },
  animate: { opacity: 1, scale: 1, transition: { duration: 0.7 } },
  exit: { opacity: 0, scale: 1, transition: { duration: 0.2 } }
};

// Wrapper component to apply animation to routes
const PageWrapper = ({ children }) => {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageVariants}
      style={{ width: "100%" }}
    >
      {children}
    </motion.div>
  );
};

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
      {/* Public Routes */}
      <Route path="/" element={<PageWrapper><MainPage /></PageWrapper>} />
      <Route path="/om_os" element={<PageWrapper><OmPage /></PageWrapper>} />
      <Route path="/faq" element={<PageWrapper><FaqPage /></PageWrapper>} />
      <Route path="/login" element={<PageWrapper><LoginPage /></PageWrapper>} />
   
      {/* Protected Routes */}
      <Route path="/profile" element={<PageWrapper><ProtectedRoute element={Profile} /></PageWrapper>} />
      <Route path="/profile/settings" element={<PageWrapper><ProtectedRoute element={Settings} /></PageWrapper>} />
      <Route path="/project/:projectId" element={<PageWrapper><ProtectedRoute element={ProjectDetails} /></PageWrapper>} />

      <Route path="/kategori" element={<PageWrapper><ProtectedRoute element={KategoriPage} /></PageWrapper>} />
      <Route path="/kategori/:id" element={<PageWrapper><ProtectedRoute element={KategoriDetailPage} /></PageWrapper>} />
      <Route path="/subkategori/:id" element={<PageWrapper><ProtectedRoute element={KategoriDetailPage} /></PageWrapper>} />
      <Route path="/produkt/:id" element={<PageWrapper><ProtectedRoute element={DetailPage} /></PageWrapper>} />
      <Route path="/projekter" element={<PageWrapper><ProtectedRoute element={ProjektPage} /></PageWrapper>} />
      
      <Route path="/produkter" element={<PageWrapper><ProtectedRoute element={ProducentTable} pAuthenticate={true} /></PageWrapper>} />
      <Route path="/produkter/correct/:id" element={<PageWrapper><ProtectedRoute element={Correctpage} pAuthenticate={true} /></PageWrapper>} />
      <Route path="/produkter/resolveVersion/:id" element={<PageWrapper><ProtectedRoute element={ResolveVersionPage} pAuthenticate={true} /></PageWrapper>} />

      {/* Misc Routes */}
      <Route path="/dev" element={<PageWrapper><ProtectedRoute element={Dev} /></PageWrapper>} />
      <Route path="*" element={<PageWrapper><h1>404 Not Found</h1></PageWrapper>} />

      {/* Conditional Route for Development Mode */}
      {process.env.NODE_ENV === 'development' && (
        <>
        <Route path="/Dev" element={<PageWrapper><Dev /></PageWrapper>} />
        <Route path="/register" element={<PageWrapper><RegisterPage /></PageWrapper>} />
        <Route path="/reset-password" element={<PageWrapper><ResetPasswordPage /></PageWrapper>} />
        <Route path="/register/student" element={<PageWrapper><StudentRegisterPage /></PageWrapper>} />
        <Route path="/register/arkeng" element={<PageWrapper><ArkEngRegisterPage /></PageWrapper>} />
        <Route path="/register/arkeng/:id" element={<PageWrapper><UserPlus /></PageWrapper>} />
        <Route path="/register/multiuser/:id" element={<PageWrapper><LinkUser /></PageWrapper>} />
        </>
  
      )}
      </Routes>
    </AnimatePresence>
  );
};

function App() {
  return (
    <GlobalProvider>
      <Router>
        <Navbar />
        <div className="main-content">
          <AnimatedRoutes />
        </div>
        <Feeter />
        <FeedbackButton />
      </Router>
    </GlobalProvider>
  );
}

export default App;
