import React from "react";
import {
  GrFacebook,
  GrLinkedinOption,
  GrInstagram,
  GrMail,
} from "react-icons/gr";
import { FiMail } from "react-icons/fi";
import "../styles/BottomSection.css";

function BottomSection() {
  const szIcon = 50;

  const logos = [
    {
      id: "Instagram",
      icon: <GrInstagram size={szIcon - 7} className="BottomSection__icon" />,
      link: "https://www.instagram.com/buildtivity/",
    },
    {
      id: "LinkedIn",
      icon: (
        <GrLinkedinOption size={szIcon - 7} className="BottomSection__icon" />
      ),
      link: "https://www.linkedin.com/company/buildtivity",
    },
    {
      id: "Mail",
      icon: <FiMail size={szIcon - 7} className="BottomSection__icon" />,
      link: "mailto:info@buildtivity.dk",
    },
  ];

  return (
    <div className="BottomSection__container">
      <div className="BottomSection__textBlock">
        <h2 className="BottomSection__header">Følg os her</h2>
        <div className="BottomSection__logoGrid">
          {logos.map((logo, index) => (
            <div key={index}>
              <a href={logo.link} target="_blank" rel="noopener noreferrer">
                {logo.icon}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BottomSection;
