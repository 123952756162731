import styles from './squarebox.module.css';
import React from 'react';
import { useState } from 'react';
import RadioButtonsGroup from '../../components/radiobuttongroup';

const Card = ({header, index, text, logo}) => {
    return (
        <div key={index} className={styles.card}>
            <div className={styles.logoBorder}>
            <img src={logo} alt="logo" className={styles.logo} />
            </div>
            <div className={styles.cardText}>
                <h2>{index}. {header}</h2>
                <p>{text}</p>
            </div>
        </div>
    )
}

function SquareBox() {

const options = [
    'Arkitekter og\nIngeniører',
    'Producenter',
  ];
  
  const [selectedValue, setSelectedValue] = React.useState(options[0]);
  
    const arkitektGroup = ()=> {
        return (
            <div className={styles.infoCard}>
                <Card
                header="Vælg kategori"
                index={1}
                text="Sorter efter, hvilket materiale eller kategori"
                logo="./images/mainpage/Kategori_Illu_Facade.svg"
                />
                <Card 
                header="Filtrer efter dine ønsker"
                index={2}
                text="Find materiale baseret på: CO2, brand, levetid, m.m."
                logo="./images/mainpage/Filterfunktioner.svg"
                />
                <Card
                header="Find det bedste byggemateriale"
                index={3}
                text="Vælg materialer, der lever op til dine krav"
                logo="./images/mainpage/Verificering_Forklaring_Level_3_Buildtivity.svg"
                />
            </div>
        )}
    
    const producentGroup = ()=> {

        return (
            <div className={styles.infoCard}>
                <Card 
                header="Upload produktblade" 
                index={1} text="Upload materialets EPD, DoP, Certificering, m.m. så bliver dataene overskuelig gjort." 
                logo="./images/mainpage/Upload_data.svg"
                />
                <Card 
                header="Nemt overblik over udløbsdatoer" 
                index={2} text="Inden jeres data blade udløber sender vi en reminder ud i god tid, så der vil være tid til at få udarbejdet et nyt." 
                logo="./images/mainpage/Udløbsdatoer.svg"
                />
                <Card 
                header="Styrk jeres salgsteam" 
                index={3} 
                text="Med et abonnement til Buildtivity kan i også gøre brug af platformen til at rådgive kunder i materialernes komplekse data." 
                logo="./images/mainpage/Styrk_Salgsteam.svg"
                /> 
            </div>
        )
    }


  return (
    <div className={styles.squareBoxGroup}>
      <div style={{ display: "flex", flexDirection: "column" ,maxWidth: "40%"}}>
            <p className={styles.squareBoxText}>
                Vi hjælper <span className={styles.boldtext}>arkitekter og ingeniører</span> med at opnå
                <span className={styles.boldtext}> informerede materialevalg</span> uden PDF'er og lange excel ark,
                og vi garanterer <span className={styles.boldtext}>opdaterede data</span> for at reducere risikoen.
            </p>
            <p className={styles.author}> - Iben Nielsen, CEO </p>
            </div>
           <div>
            <RadioButtonsGroup
            options={options}
            selectedValue={selectedValue}
            handleRadioChange={(e) => setSelectedValue(e.target.value)}
            />

            </div>
            <div className={styles.infoBox}>
                {selectedValue === options[0] ? arkitektGroup() : producentGroup()}
            </div>
  
    </div>
  );
}

export default SquareBox;
