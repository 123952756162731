import styles from './safetyexpl.module.css';
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import { useState } from 'react';

const Card = ({ info }) => {
    return (
        <div vclassName={styles.card}>
            <div>
                <h4 className={styles.cardTitle}>{info.level}</h4>
            </div>
            <div className={styles.cardContent}>
                <img src={info.icon} alt="logo" className={styles.logo} />
                <div className={styles.cardText}>
                    <h2 style={{ fontSize: "1vw", margin: "0 0" }}>{info.header}</h2>
                    <p style={{ fontSize: "0.7vw" }}>{info.text}</p>
                </div>
            </div>
        </div>
    );
};

function SafetyLevelExplainer() {
    const cardInfo = {

        level0: {
            header: "AI behandlet data",
            level: "Level 0",
            text: "Dokumentet er blevet behandlet af vores AI-model, som har udtrukket og omregnet dataene. Bemærk, at resultaterne ikke er verificeret yderligere og bør gennemgåes for endelig validering.",
            icon: `${process.env.PUBLIC_URL}/safetyLevel/Verificering Ikoner_Level 0.svg`
        },
        level1: {
            header: "Data direkte fra datablade",
            level: "Level 1",
            text: "Dataene er udtrukket direkte fra databladene og præsenteres i deres oprindelige form udenomregning eller yderligere behandling. Dette svarer til at læse informationen direkte fra databladene.",
            icon: `${process.env.PUBLIC_URL}/safetyLevel/Verificering Ikoner_Level 1.svg`
        },
        level2: {
            header: "Data verificeret af producent",
            level: "Level 2",
            text: "Dataene er udtrukket direkte fra databladene og verificeret af producenterne. Dette sikrer, at informationen er pålidelig og i overensstemmelse med originalkilden.",
            icon: `${process.env.PUBLIC_URL}/safetyLevel/Verificering Ikoner_Level 2.svg`
        },
        level3: {
            header: "Data verificeret af Builditivity",
            level: "Level 3",
            text: "Dataene er blevet verificeret af Buildtivity, hvilket sikrer høj kvalitet både i de forskellige enhedsomregninger og i de udtrukne oplysninger fra databladene.",
            icon: `${process.env.PUBLIC_URL}/safetyLevel/Verificering Ikoner_Level 3.svg`
        }
    };
    // Convert cardInfo object values to an array of groups
    const cardGroups = [
        [cardInfo.level0, cardInfo.level1],
        [cardInfo.level2, cardInfo.level3]
    ];

    // State to track the currently displayed group
    const [currentGroup, setCurrentGroup] = useState(0);

    // Switch to the next group
    const handleNextGroup = () => {
        setCurrentGroup((prev) => (prev === 0 ? 1 : 0));
    };

    // Switch to the previous group
    const handlePreviousGroup = () => {
        setCurrentGroup((prev) => (prev === 0 ? 1 : 0));
    };

    return (
        <div className={styles.container}>
            <h1>Fuld Gennemsigtighed</h1>
            <p style={{ fontSize: "1.2rem" }}>
                Sådan behandler vi dataene. Hver materiale har et level:
                <span style={{ fontWeight: "bold" }}> Level 0 - </span>
                <span style={{ fontWeight: "bold" }}> Level 1 - </span>
                <span style={{ fontWeight: "bold" }}> Level 2 - </span>
                <span style={{ fontWeight: "bold" }}> Level 3</span>
            </p>

            <div className={styles.wrapper}>
                <SlArrowLeft className={styles.arrow} onClick={handlePreviousGroup} />

                <div className={styles.cardContainer}>
                    <div
                    className={styles.slidingContainer}
                    style={{ transform: `translateX(-${currentGroup * 100}%)` }}
                    >
                    {cardGroups.map((group, index) => (
                        <div key={index} className={styles.group}>
                        {group.map((info, i) => (
                            <Card key={i} info={info} />
                        ))}
                        </div>
                    ))}
                    </div>
                </div>

                <SlArrowRight className={styles.arrow} onClick={handleNextGroup} />
                </div>

        </div>
    );
}

export default SafetyLevelExplainer;
