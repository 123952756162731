import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchSubcategories } from '../components/APIS/categoryAPI';
import { AnimatePresence, motion } from 'framer-motion';

// Import category images
import Facade from './Kategori/kategori_billeder/Facade.svg';
import Fundament from './Kategori/kategori_billeder/Fundament.svg';
import Gulv from './Kategori/kategori_billeder/Gulv.svg';
import Inderveg from './Kategori/kategori_billeder/inderveg.svg';
import Isolering from './Kategori/kategori_billeder/Isolering.svg';
import Konstruktion from './Kategori/kategori_billeder/Konstruktion.svg';
import Loft from './Kategori/kategori_billeder/Loft.svg';
import Prefabrikerede_Elementer from './Kategori/kategori_billeder/Prefabrikerede_Elementer.svg';
import Tag from './Kategori/kategori_billeder/Tag.svg';

import RadioButtonsGroup from '../components/radiobuttongroup';
import './styles/KategoriPage.css';

function KategoriPage() {
  const [subcategories, setSubcategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Set initial radioValue from URL or default to "Konstruktioner"
  const initialRadioValue = searchParams.get('type') || 'Konstruktioner';
  const [radioValue, setRadioValue] = useState(initialRadioValue);

  useEffect(() => {
    // Update the URL parameter whenever radioValue changes
    setSearchParams({ type: radioValue });
  }, [radioValue, setSearchParams]);

  const hardcodedCategories = [
    'Facade', 'Fundament', 'Gulv', 'Indervæg', 'Isolering', 
    'Konstruktion', 'Loft', 'Præfabrikerede Elementer', 'Tag'
  ];

  const getImageByCategory = (category) => {
    switch (category.toLowerCase()) {
      case 'facade': return Facade;
      case 'fundament': return Fundament;
      case 'gulv': return Gulv;
      case 'indervæg': return Inderveg;
      case 'isolering': return Isolering;
      case 'konstruktion': return Konstruktion;
      case 'loft': return Loft;
      case 'præfabrikerede elementer': return Prefabrikerede_Elementer;
      case 'tag': return Tag;
      default: return null;
    }
  };

  const handleBoxClick = (title) => {
    navigate(`/kategori/${title}`, { state: { category: title } });
  };

  const boxes = hardcodedCategories.map((title, index) => (
    <div key={index} className="box">
      <img
        src={getImageByCategory(title)}
        className="box-img"
        alt={title}
        onClick={() => handleBoxClick(title)}
      />
      <h3 className="box-title" onClick={() => handleBoxClick(title)}>{title}</h3> 
    </div>
  ));

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  useEffect(() => {
    fetchSubcategories()
      .then((data) => {
        setSubcategories(data);
        // console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching subcategories:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const formatSubcategory = (name) => {
    if (typeof name !== 'string') {
      return name;  // Or another default value
    }
    
    return name
      .replace(/ae/g, 'æ')                  
      .replace(/([A-Z])/g, ' $1')             
      .replace(/^./, str => str.toUpperCase()); 
  };
  

  const handleMaterialClick = (title) => {
    const formattedTitle = title.replace(/ /g, '-');
    navigate(`/subkategori/${formattedTitle}`, { state: { category: formattedTitle } });
  };
  

  const materialContent = () => {
    
    if (!subcategories || subcategories.length === 0) {
      return <p>No data</p>;
    }
    return (
      <div className="material-columns">
        {subcategories
          .filter((material) => typeof material === 'string' && material.trim() !== '')  // Filters out null, undefined, and empty strings
          .map((material, index) => (
            <div key={index} className="material-column">
              <p onClick={() => handleMaterialClick(material)}>{formatSubcategory(material)}</p>
            </div>
          ))
        }

      </div>
    );
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = () => {
    alert(inputValue);
  };

  return (
    <div style={{ paddingBottom: '20px', paddingTop: '40px' }}>
      <div className="page-kategori-container">
        <div className="logo-container"></div>
        <input
          type="text"
          placeholder="Feature coming soon"
          className="search-input"
          value={inputValue}
          onChange={handleInputChange}
        />
        <button className="search-button" onClick={handleButtonClick}>
          Søg
        </button>
      </div>

      <div className="searchbar-and-radio">
        <RadioButtonsGroup 
          options={['Konstruktioner', 'Materialer']} 
          selectedValue={radioValue} 
          handleRadioChange={handleRadioChange} 
        />
      </div>



      <AnimatePresence mode="wait">
  {radioValue === 'Konstruktioner' ? (
    <motion.div
      key="konstruktioner"
      style={{ paddingTop: '15px' }}
      className="boxes-container"
      initial={{ opacity: 0, x: 10 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ duration: 0.3 }}
    >
      {boxes}
    </motion.div>
  ) : (
    <motion.div
      key="materialContent"
      initial={{ opacity: 0, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      transition={{ duration: 0.3 }}
    >
      {materialContent()}
    </motion.div>
  )}
</AnimatePresence>


    </div>
  );
}

export default KategoriPage;
