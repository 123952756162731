import React from "react";
import styles from "./OmPageBanner.module.css";

function OmPageBanner() {
  const imagePath = "images/omOs/banner.png";
  const headline = "Besøg os på disse messer";
  const bulletPoints = ["Building Green", "Building Tech", "Byggerimessen"];

  return (
    <div className={styles.omPageBanner}>
      <div className={styles.overlay}>
        <h3 className={styles.overlayHeadline}>{headline}</h3>
        {bulletPoints.map((bulletPoint, index) => (
          <p key={index} className={styles.text}>
            {bulletPoint}
          </p>
        ))}
      </div>
      <img src={imagePath} alt="Banner" className={styles.omPageBannerImage} />
    </div>
  );
}

export default OmPageBanner;
