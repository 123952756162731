import styles from './mainpage.module.css'; // Import the CSS module
import Banner from './Banner'; // Import the Banner component

const imageurls = [
  { url: './images/mainpage/img1.jpg' },
  { url: './images/mainpage/img2.jpg' },
  { url: './images/mainpage/img3.jpg' },
  { url: './images/mainpage/img4.jpg' },
];

const ImageRenderer = () => {
  return (
    <div className={styles.imageContainer}>
    <img src="/images/mainpage/img1.jpg" alt="Image 1" className={styles.imagesMain}/>
    <img src="/images/mainpage/img2.jpg" alt="Image 2" className={styles.imagesMain}/>
    <img src="/images/mainpage/img3.jpg" alt="Image 3" className={styles.imagesMain}/>
    <img src="/images/mainpage/img4.jpg" alt="Image 4" className={styles.imagesMain}/>

      <Banner />
    </div>
  );
};

export default ImageRenderer;
