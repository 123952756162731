import React from 'react';
import styles from './Box.module.css';

export const Box = ({ navi, onClick, isActive }) => {
  return (
    <div
      className={`${styles.boxstyle} ${isActive ? styles.active : ''}`} 
      onClick={onClick} 
    >
      <h3>{navi.title}</h3>
    </div>
  );
};
