
import React from 'react';
import Signup from '../Redundant Code/Signup';
import UploadTable from './Upload/producentTable';
import Showallicons from '../ICONS';
import SafetyLevelIcon from '../components/safetylevel';
import Loader from '../components/Utils/Loader';
import Tooltip from '../components/Utils/tooltip';
import FAQPage from './FaqPage';

function Dev() {

    


    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",paddingTop: "10vh", paddingBottom: "10vh" }}>
            {/* <Tooltip
            size={20}
            link="https://example.com"
            tip="GWP is a measure of how much heat a greenhouse gas traps in the atmosphere over a specific time period, relative to carbon dioxide. It is expressed as the equivalent amount of CO2 that would have the same global warming impact."
            /> */}


        {/* <Loader /> */}
        {/* <FAQPage /> */}
        {/* <Signup /> */}
        {/* <UploadTable /> */}
        {/* <Showallicons/> */}
    {/* {SafetyLevelIcon({ safetyLevel: 0 })}
    {SafetyLevelIcon({ safetyLevel: 1 })}
    {SafetyLevelIcon({ safetyLevel: 2 })}
    {SafetyLevelIcon({ safetyLevel: 3 })}      */}
        {/* <img src='./images/loader.svg' alt="gay Nigga" /> */}
        </div>
    );

    
}

export default Dev;
