import { useParams } from "react-router-dom";
import styles from './RegisterPage.module.css'
function LinkUser( ){

    const {id} = useParams();
    const link = window.location.origin + "/register/arkeng/" + id;
    const handlecopylink = () => {
        navigator.clipboard.writeText(link);
    }

    return (
        <div  className={styles.pageStyle}>
            <h1 >Link til oprettelse af flere brugere</h1>
            <p>Send dette link til de brugere du vil have tilknyttet din virksomhed</p>
            
            <a style={{textDecoration:"none",color:"var(--Green)"}} href={link}>{link}</a>
            <button className={styles.submitButton} onClick={handlecopylink }>Kopier Link</button>
        </div>
    )
}

export default LinkUser;