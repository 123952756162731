import React, { useState, useEffect } from 'react';
import ImageRenderer from './imagecontainer';
import SquareBox from './squarebox';
import SafetyLevelExplainer from './safetyexpl';

function MainPage() {

  return (
    <>
      <div style={{ 
        display: "flex", 
        flexDirection: "column", 
        alignItems: "center", 
        justifyContent: "center",
      }}>
          <ImageRenderer />
      
          <SquareBox />

          <SafetyLevelExplainer />
      </div>
    </>
  );
  
}

export default MainPage;



// <ImageRenderer />   
// <SquareBox />
// <SafetyLevelExplainer />