import React from "react";
import Team from "./Team.js";
import FeatureBar from "./FeatureBar.js";
import BottomSection from "./BottomSection.js";
import OmPageBanner from "./OmPageBanner.js";
import styles from "./OmPage.module.css";

function OmPage() {
  const bodyText =
    "Ved at Buildtivity optimerer og informerer om valget af byggematerialer, sikrer vi branchens grønne omstilling i en tid med stigende lovkrav, dokumentationskrav og klimaudfordringer. Dette gør virksomheder – især SMV’er – konkurrencedygtige og kvalificerede på et nyt grønt marked. Vi står overfor klimaudfordringer og klimamål, som bliver en samfundsmæssig udfordring at løse. Her skal transparent dokumentation styrke tilliden i byggebranchen, hvilket er essentielt for at opnå reel bæredygtighed i byggeprojekter.";

  const imagePath = "images/omOs/banner.png";

  return (
    <div className={styles.omPageContainer}>
      <OmPageBanner />
      <div className={styles.omPageSplit}>
        <div className={styles.omPageSplitText}>
          <h2 className={styles.splitHeadline}>
            Mod et overskueligt materialevalg
          </h2>
          <div className={styles.omPageSplitText}>{bodyText}</div>
        </div>
        <img
          className={styles.omPageSplitImage}
          src="/images/omOs/Bottom.jpg"
        />
      </div>
      <FeatureBar />
      <Team />
      <BottomSection />
    </div>
  );
}

export default OmPage;
